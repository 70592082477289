<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-files"> </i> 档案管理 | 项目空间</h4>
          </div>
          <div class="col-sm-8">
            <span style="font-size: 20px; font-weight: bold;line-height: 2.5em">
              {{ archive.NAME }}
              <span v-if="cp_id!=''">/{{cp_name}}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="content">
          <el-container style="height: 900px; background: #fff; border: 1px solid #fff; border-radius: 15px">
            <el-aside width="300px" style="border-right: solid 1px #ccc;padding: 10px">
              <el-tree :data="ls_dir" :props="{label:'NAME',isLeaf:'LEAF',children:'CHILDREN'}" node-key="ID"
              :default-expanded-keys="['']" @node-click="loadFile" highlight-current>
                <el-row slot-scope="{node,data}" style="width: 100%;">
                  <el-col :span="22" style="font-size: 15px; ">{{data.NAME}}</el-col>
                  <el-col :span="2" style="text-align: right" v-if="archive.STA!=9">
                    <el-popover placement="right" trigger="hover">
                      <i slot="reference" class="el-icon-setting" />
                      <div>
                        <el-button type="text" icon="el-icon-plus" @click="dirEdit(data,1)">新增</el-button>
                        <el-button type="text" icon="el-icon-edit" @click="dirEdit(data,2)">修改</el-button>
                        <el-button type="text" icon="el-icon-delete" @click="dirDel(data)">删除</el-button>
                      </div>
                    </el-popover>
                  </el-col>
                </el-row>
              </el-tree>
            </el-aside>
            <el-main>
              <el-row :gutter="10">
                <el-col :span="4" v-if="archive.STA!=9">
                  <div style="width: 160px; height: 180px; text-align: center; cursor:pointer" @click="upload">
                    <div>
                      <el-image :src="require('@/assets/ext/upload.png')" fit="fit" style="width: 90px" />
                    </div>
                    <div>上传</div>
                  </div>
                </el-col>
                <el-col :span="4" v-for="it in ls_file" :key="it.ID">
                  <el-popover placement="top" trigger="hover">
                    <div style="width: 160px; height: 180px; text-align: center" slot="reference">
                      <div>
                        <el-image :src="require('@/assets/ext/'+getExtImg(it.NAME))" fit="fit" style="width: 90px" />
                      </div>
                      <div>{{it.NAME}}</div>
                    </div>
                    <div style="text-align: center">
                      <el-link type="primary" :href="'/doc/content/download/'+it.GUID" target="_blank">
                        <i class="el-icon-download" />
                        下载
                      </el-link>
                      <el-link type="primary" @click.native="fileDel(it.ID)" style="margin-left: 20px; color: #409EFF">
                        <i class="el-icon-delete" />
                        删除
                      </el-link>
                    </div>
                  </el-popover>
                </el-col>
              </el-row>
            </el-main>
          </el-container>
      </div>
    </div>
    <div>
      <Edit ref="dir_edit" />
    </div>
    <div>
      <Upload ref="upload" />
    </div>
  </div>
</template>
<script>
import Edit from "./edit";
import Upload from "./upload"
export default {
  name: "index",
  components: {Edit,Upload},
  data(){
    return{
      archive:this.$route.params,
      ls_file:[],
      ls_dir:[],
      cp_id: "",
      cp_name:""
    }
  },
  created() {
    if (this.$route.params.ID===undefined)
      this.$router.push({path:'/doc/archive'})
    else{
      this.loadFile();
      this.loadDir();
    }
  },
  methods:{
    loadDir(){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/DOC/ContentApi/GetTree",
        data: {
          AID:self.archive.ID,
          TYPE:1,
          PARENT_ID:0
        },
        completed: function (its) {
          let t={ID:"",NAME:'根目录',LEAF:false,CHILDREN:its.DATA,AID:self.archive.ID};
          self.ls_dir=[t];
        }
      })
    },
    dirEdit(dt,op){
      this.$refs.dir_edit.init(dt,op);
    },
    dirDel(dt){
      let self = this;
      if (dt.ID==""){this.whale.toast.err("不能删除根目录");return}
      this.whale.delConfirm(()=>{
        this.whale.remote.getResult({
          url: "/api/School/DOC/ContentApi/Delete",
          data: {ID:dt.ID},
          completed: function () {
            self.loadDir()
            if (self.cp_id==dt.ID){
              self.cp_id="";
              self.loadFile();
            }
          }
        })
      });
    },
    loadFile(data){
      let self = this;
      if (data!==undefined) {
        this.cp_id=data.ID
        this.cp_name=data.NAME
      }
      this.whale.remote.getCollection({
        url: "/api/School/DOC/ContentApi/GetList",
        data: {
          AID:self.archive.ID,
          TYPE:2,
          PARENT_ID:this.cp_id
        },
        completed: function (its) {
          self.ls_file=its;
        }
      })
    },
    getExtImg(fn){
      let spl=fn.split('.');
      let n=spl.length
      if (n>=2) {
        let ext=spl[n-1];
        if (ext=="doc" || ext=="docx") return "word.png";
        else if (ext=="xls" || ext=="xlsx") return "excel.png";
        else if (ext=="ppt" || ext=="pptx") return "ppt.png";
        else if (ext=="pdf") return "pdf.png";
        else if (ext=="wps") return "wps.png";
        else if (ext=="jpg" || ext=="jpeg"|| ext=="png"|| ext=="gif"|| ext=="bmp") return "pic.png";
        else if (ext=="txt") return "txt.png";
        else return "unknow.png"
      }else
          return "unknow.png"
    },
    upload(){
      this.$refs.upload.init(this.archive.ID,this.cp_id);
    },
    fileDel(id){
      let self = this;
      this.whale.delConfirm(()=>{
        this.whale.remote.getResult({
          url: "/api/School/DOC/ContentApi/Delete",
          data: {
            ID:id
          },
          completed: function () {
            self.loadFile()
          }
        })
      })
    }
  }
}
</script>

